import axios, {AxiosRequestConfig} from "axios";
import retry from "axios-retry";
import {StorageManager} from "@features/storage-manager/storage-manager";
import {MINUTE, SECOND} from "@shared/lib/timeUnits";
import {debugLog} from "@features/debug/debbug";
import {toast} from "react-toastify";

let instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: process.env.NODE_ENV === 'development' ? MINUTE : 60 * SECOND,
    headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
    },
});

retry(instance, {
    retries: 3, retryDelay: retry.exponentialDelay, retryCondition: error => {
        if (!error || !error?.response) return true
        if (error.response && [500, 401, 400, 403, 422, 404].includes(error.response.status)) return false
        return true
    }
});

instance.interceptors.request.use((config) => {
    //@ts-ignore-next-line
    const account = window.location.pathname.split('/').filter(Boolean)[1]

    config.headers["App-Auth-Token"] = StorageManager.getFromLocal(
        StorageManager.KEYS.AUTH_TOKEN,
    );
    config.headers["App-Account-Token"] =  account && account.includes('-') ? account : StorageManager.getFromLocal(StorageManager.KEYS.LAST_ACCOUNT_ID,
    );

    config.headers["App-Lang"] = StorageManager.getFromLocal(StorageManager.KEYS.LAST_LANG)
    return config;
});

export const fetcher = async <T extends Object>(
    url?: string,
    options: AxiosRequestConfig = {},
): Promise<T> => {
    if (!url) return null as unknown as T;
    let start = Date.now()
    const response = await instance({
        url,
        ...options
    }).then(res => {
        const delta = (Date.now() - start)
        if (delta > 2e3) debugLog(`[API] ${decodeURI(url)} - ${delta}ms`)
        return res
    }).catch((error) => {
        debugLog(decodeURI([error?.response?.request?.responseURL, error.response?.data?.message ?? error.message].join(' - ')))
        if (error?.response?.status === 401) {
            const lang = StorageManager.getFromLocal(StorageManager.KEYS.LAST_LANG)
            localStorage.clear()
            window.location.href = `/${lang || 'ua'}/login?from=${window.location.pathname}`
        }
        if(error?.response?.status === 403) {
            toast.error(`#403 ${error.response.data.message}`)
        }
        if (error?.response) return Promise.reject({...error.response.data, status: error.response.status});
        throw error;
    });
    return response.data;
};
