export enum DateFormat {
    TIME,
    TIME_FULL,
    DATE,
    DATE_FULL,
    DATE_SHORT,
    TIME_ONLY,
    HOUR,
}

export const INTERNATIONAL_DATE = {
    [DateFormat.DATE_SHORT]: "DD.MM",
    [DateFormat.DATE_FULL]: "DD.MM.YYYY HH:mm",
    [DateFormat.DATE]: "DD.MM.YYYY",
    [DateFormat.TIME]: "HH:mm",
    [DateFormat.TIME_FULL]: "HH:mm:ss",
    [DateFormat.TIME_ONLY]: "HH:mm",
    [DateFormat.HOUR]: "HH",
};

export const US_DATE = {
    [DateFormat.DATE_SHORT]: "MM/DD",
    [DateFormat.DATE_FULL]: "MM/DD/YYYY hh:mm A",
    [DateFormat.DATE]: "MM/DD/YYYY",
    [DateFormat.TIME]: "hh:mm A",
    [DateFormat.TIME_FULL]: "hh:mm A",
    [DateFormat.TIME_ONLY]: "hh:mm",
    [DateFormat.HOUR]: "hh A",
};

export const dateFormatter =
    (isUsFormat = false) =>
        (type: DateFormat) => {
            if (isUsFormat) {
                return US_DATE[type];
            }
            return INTERNATIONAL_DATE[type];
        };
