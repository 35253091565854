import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import en from "./locales/en.json";
import ua from "./locales/ua.json";
import ru from "./locales/ru.json";
import es from "./locales/es.json";
import dayjs from "dayjs";
import {registerLocale, setDefaultLocale} from "react-datepicker";
import dayjsUa from "dayjs/locale/uk";
import dayjsRu from "dayjs/locale/ru";
import dayjsEs from "dayjs/locale/es";
import {ru as fnsRu} from "date-fns/locale/ru";
import {uk as fnsUa} from "date-fns/locale/uk";
import {es as fnsEs} from "date-fns/locale/es";
import {Locale} from "date-fns";
import {StorageManager} from "@features/storage-manager/storage-manager";

export const LOCALES = ["en", "ua", "ru", 'es']
const resources = {
  en: {
    translation: en,
  },
  ua: {
    translation: ua,
  },
  ru: {
    translation: ru,
  },
  es: {
    translation: es,
  },
};
const dayjsLocaleMapping = {
  ua: dayjsUa,
  ru: dayjsRu,
  es: dayjsEs
};
const url = new URL(window.location.href).pathname
  .split("/")
  .filter(Boolean)[0];
let locale = "";
if (["en", "ua", "ru",'es'].includes(url)) {
  locale = url;
} else {
  StorageManager.getFromLocal(StorageManager.KEYS.LAST_LANG, "ua");
}

i18n.use(initReactI18next).init({
  fallbackLng: "ua",
  lng: locale,
  resources,
  interpolation: {
    escapeValue: false,
  },
});

export const initLocales = (lang?: string) => {
  if(lang) locale = lang;
  i18n.changeLanguage(locale);
  if (dayjsLocaleMapping[locale])
    dayjs.locale(locale, dayjsLocaleMapping[locale]);

  registerLocale("ru", fnsRu as unknown as Locale);
  registerLocale("ua", fnsUa as unknown as Locale);
  registerLocale("es", fnsEs as unknown as Locale);
  setDefaultLocale(locale);
};

export default i18n;
