import useSWR from "swr";
import {useAuth} from "@entities/auth/lib/hooks";
import {CompanyRepository} from "@src/repositories/CompanyRepository";
import {useParams} from "react-router";
import {once, uniqBy} from "lodash-es";
import {CompanyWithAddressDTO} from "@dto/CompanyDTO";
import {BaseRouteParams} from "@routes/api_routes";
import {isAllSelected} from "@shared/lib/helpers";
import {useEffect, useMemo} from "react";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";

const notifyNotAllowed = once((t: (s:string) => string) => {
    toast.error(t('address_not_allowed'))
})
export const useCompanyAddresses = (loadData = true) => {
    const {userId, isAuthorized} = useAuth()
    const {accountId, companyAddressId, companyId} = useParams<BaseRouteParams>()
    const {t} = useTranslation()
    const data = useSWR(
        loadData && isAuthorized && `/user/${userId}/companies`,
        () => CompanyRepository.getUserCompanies(accountId as string, userId),
        {
            dedupingInterval: 6e4
        })
    const isAllowed = useMemo(() => {
        if(isAllSelected(companyAddressId)) return true
        if(isAllSelected(companyId)) return true
        return data?.data?.items?.some(e => {
            const isCompanyAllowed = isAllSelected(companyId) ? true: e.id === companyId
            const isAddressAllowed = isAllSelected(companyAddressId) ? true: e.addresses.some(a => a.id === companyAddressId)
            return isCompanyAllowed && isAddressAllowed
        });
    }, [companyAddressId, data.data])
    const toOptions = () => {
        if (!data.data) return []

        let res = data.data.items.map(company => ({
            title: `${company.name} (${company.cityName})`,
            value: company.id,
            status: company.status,
            addresses: company.addresses.map(address => ({title: address.address, value: address.id}))
        }))
        res = uniqBy(res, 'value')
        return res
    }


    useEffect(() => {
        if(isAllowed) return
        if(!data.data) return;
        notifyNotAllowed(t)
    }, [isAllowed]);

    return {
        ...data,
        isAllowed,
        toOptions
    }
}

export const useCompany = (load = true) => {
    const {userId, isAuthorized, isAllCompaniesSelected} = useAuth()
    const {accountId, companyId, companyAddressId} = useParams<BaseRouteParams>()
    const {isAllowed} = useCompanyAddresses()

    return useSWR<CompanyWithAddressDTO>(
        isAuthorized && load && isAllowed && !isAllCompaniesSelected && companyId && `/user/${userId}/companies/${companyId}`,
        () => CompanyRepository.getCompany(accountId as string, companyId as string, companyAddressId as string))

}