import {dateFormatter} from "@features/date/lib/date-format";
import {useRef} from "react";
import {useAccountSettings} from "@entities/settings/lib/hooks";

export const useDateFormatter = () => {
    const {data: settings} = useAccountSettings()
    const isUsFormat = settings?.dateFormatType === 'US'
    const isInternationalFormat = !isUsFormat


    const formatter = useRef(dateFormatter(isUsFormat)).current;

    return {
        getDateFormat: formatter,
        isUsFormat,
        isInternationalFormat,
    };
};
