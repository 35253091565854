import {isNil, isNumber, lowerCase, snakeCase} from "lodash-es";
import dayjs, {Dayjs} from "dayjs";

export const enumToString = (
    value: any,
    options: Record<any, any>,
    namespace: string,
) => {
    const spacer = namespace ? "_" : "";
    const isAllSameKeys = Object.keys(options).every(
        (key) => lowerCase(key) === lowerCase(options[key]),
    );
    for (const option of Object.entries(
        isAllSameKeys ? options : enumToObject(options),
    )) {
        if (option[1] === value) {
            return `${namespace}${spacer}${snakeCase(option[0])}`;
        }
    }
    return "";
};

export const enumToObject = (data: Record<any, any>): Record<any, any> => {
    let k = Object.entries(data);
    const isNumeric = k.every(([key, value]) => !isNumber(value));
    if (!isNumeric) {
        k = k.slice(Math.floor(k.length / 2));
    }

    return Object.fromEntries(k);
};

export const roundToNearestHour = (date: Dayjs | Date): Dayjs => {
    const tmp = dayjs(date);
    const minutes = tmp.minute();
    if (minutes <= 30) {
        return tmp.startOf("hour");
    }

    return tmp.startOf("hour").add(1, "hour");
};

export const wrap = (val: string | number, prefix: string, suffix?: string) => {
    if (!suffix) suffix = prefix;

    return `${prefix}${val}${suffix}`;
};

export const formatUrl = (
    path: string,
    params?: Record<string, string | number | undefined>,
) => {
    const search = new URLSearchParams();
    let url = path

    if (params) {
        for (const key in params) {
            if (isNil(params[key])) continue;
            const exists = url.includes(`:${key}`);

            if (exists) {
                url = url.replace(`:${key}`, String(params[key]));
                search.delete(key);
            } else {
                search.set(key, String(params[key]));
            }
        }
    }
    if (search.toString()) {
        url += `?${search.toString()}`;
    }

    url = url
        .split("/")
        .filter((e) => !e.includes(":"))
        .join("/");

    return url;
};

export const truncateMiddle = (text: string, length: number) => {
    if (text.length <= length) return text;

    const start = text.slice(0, (length - 3) / 2);
    const end = text.slice(text.length - (length - 3) / 2);

    return `${start}...${end}`;
};

export  const truncateEnd = (text: string, length: number) => {
    if (text.length <= length) return text;

    return `${text.slice(0, length -3)}...`;
}


export const getErrorMessage = (e: any) : string => {
    if ('message' in e) return e.message
    if ('error' in e) return e.error
    if ('errors' in e) {
        if(Array.isArray(e.errors)) return e.errors[0]
        if(typeof e.errors === 'object') return String(Object.values(e.errors)[0])
        return 'generic_error'
    }
    return 'generic_error'
}


export const isAllSelected = (id?: string) => {
    if(!id) return false
    return id === 'all'
}

export const mapDatesToFilters = (dates: Date[] | undefined | null) => {
    const res = {fromTime: 0, toTime: 0}
    if(!dates) return res
    if(dates[0]) res.fromTime = dayjs(dates[0]).unix()
    if(dates[1]) res.toTime = dayjs(dates[1]).unix()

    return res
}

export const downloadFile = (data: string, fileType: string, name: string) => {
    const blob = new Blob([data], {type:`${fileType}`});
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", name);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export const safeSum = (...arr: any[]): number => {
    return arr.reduce((acc, item) => {
        if(isNumber(+item)) return acc + +item
        return acc
    }, 0)
}

export const safeMul = (a: any, b: any): number => {
    if(isNumber(+a) && isNumber(+b)) return +a * +b
    return 0
}