import React, {FC, lazy, PropsWithChildren, ReactElement} from "react";
import {Header} from "@features/header/header";

const Sidebar = lazy(() => import(/*webpackChunkName: "sidebar"*/ "@entities/sidebar/sidebar").then(e => ({default: e.Sidebar})));
export const WithMenuLayout: FC<
  PropsWithChildren & { headerContent: ReactElement }
> = ({ children, headerContent }) => {
  return (
    <div>
      <Header showMenuButton showNotificationButton children={headerContent} />
      <Sidebar />
      <div className="content-main content-main-padding animate__animated animate__fadeIn">{children}</div>
    </div>
  );
};
