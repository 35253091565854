import React, {cloneElement, FC, PropsWithChildren} from "react";
import classNames from "classnames";

export const Pill: FC<
    PropsWithChildren & {
    icon?: React.ReactElement;
    className?: string;
    title?: string;
    medium?: boolean;
    type?: "info" | "success" | "warning" | "notice" | "ready";
    radius?: "md" | "lg" | "full";
    onClick?: () => void;
}
> = ({
         children,
         type = "info",
         icon,
         radius = "md",
         medium = true,
         ...props
     }) => {
    const classMapping = {
        "bg-gray-100 dark:bg-gray-600 text-gray-600 dark:text-white/60":
            type === "info",
        "bg-[#FF8A4C1A] dark:text-white text-gray-900":
            type === "warning" || type === "success",
        "bg-green-100 dark:bg-green-900": type === "notice",
        'bg-[#31C48D1A]': type === "ready",
    };
    const iconClassMapping = {
        "text-gray-600 dark:text-white/60": type === "info",
        "text-orange-500": type === "warning" || type === "success",
        "text-black dark:text-white": type === "notice",
        "text-green-500": type === "ready",
    };
    const inShortText = typeof children === "string" && children.length < 30;
    return (
        <div
            {...props}
            className={classNames(
                classMapping,
                {"text-nowrap truncate": inShortText, 'cursor-pointer': props.onClick},
                `rounded-${radius}`,
                "text-base px-2 py-1 flex gap-2.5 items-start",
                props.className,
                {"font-medium": medium},
            )}
        >
            {icon &&
                cloneElement(icon, {
                    className: classNames(
                        !icon?.props?.className?.includes("w-")
                            ? "min-w-6 min-h-6 w-6 h-6"
                            : icon?.props?.className,
                        iconClassMapping,
                    ),
                })}
            {children}
        </div>
    );
};
