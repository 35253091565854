import {useTranslation} from "react-i18next";
import React, {FC, lazy, PropsWithChildren, Suspense, useState} from "react";
import {WithMenuLayout} from "@layouts/with-menu-layout";
import {HeaderTabs} from "@features/header-tabs";
import {Outlet} from "react-router";
import {ROUTES} from "@routes/routes-list";
import {useOrdersList} from "@entities/orders/lib/hooks";
import {
    new_orders_tab_selector,
    now_orders_tab_selector,
    pre_orders_tab_selector
} from "@entities/orders/lib/selectors";
import {useCompany} from "@entities/company/lib/hooks";
import {COMPANY_ADDRESS_STATUS} from "@dto/CompanyAddressDTO";
import dayjs from "dayjs";
import {DateFormat} from "@features/date/lib/date-format";
import {useDateFormatter} from "@features/date/lib/useDateFormatter";
import {enumToString} from "@shared/lib/helpers";
import {Pill} from "@shared/ui/pill";
import classNames from "classnames";
import {CheckCircle, PauseIcon} from "@features/icons";

const StatusSelector = lazy(() => import("@entities/sidebar/ui/status-selector"));
export const OrdersPageLayout: FC<PropsWithChildren> = ({children}) => {
    const {t} = useTranslation();
    const {groupedOrders} = useOrdersList()
    const headerLinks = [
        {
            title: t("new_orders"),
            route: ROUTES.NEW_ORDERS,
            badge: String(groupedOrders.newOrders?.length || ''),
            'data-testid': new_orders_tab_selector,
        },
        {
            title: t("now_orders"),
            route: ROUTES.NOW_ORDERS,
            badge: String(groupedOrders.now?.length || ''),
            'data-testid': now_orders_tab_selector,
        },
        {
            title: t("later_orders"),
            route: ROUTES.LATER_ORDERS,
            badge: String(groupedOrders.preorder?.length || ''),
            'data-testid': pre_orders_tab_selector,
        },
    ];
    const {getDateFormat} = useDateFormatter()
    const [isStatusOpen, setStatusOpen] = useState(false)

    const {data: company} = useCompany(true)

    const iconsMapping = {
        [COMPANY_ADDRESS_STATUS.ACTIVE]: (
            <CheckCircle className={"text-green-500 w-6 h-6"}/>
        ),
        [COMPANY_ADDRESS_STATUS.HIDDEN]: (
            <CheckCircle className={"text-green-500 w-6 h-6"}/>
        ),
        [COMPANY_ADDRESS_STATUS.INACTIVE]: (
            <PauseIcon className={"text-orange-400 w-6 h-6"}/>
        ),
    };


    const renderTime = () => {
        if (!company) return null
        if (company.companyAddress.status === COMPANY_ADDRESS_STATUS.INACTIVE) {
            if (!company.companyAddress.scheduledStatusUpdateTime) {
                return t(enumToString(company.companyAddress.status, COMPANY_ADDRESS_STATUS, 'company_address'))
            }
            const dueDate = dayjs.unix(company.companyAddress.scheduledStatusUpdateTime)
            let time = dueDate.format(getDateFormat(DateFormat.DATE_SHORT) + ' ' + getDateFormat(DateFormat.TIME))
            if (dueDate.isToday()) {
                time = dueDate.format(getDateFormat(DateFormat.TIME))
            }
            return `${t('paused_till')} ${time}`
        }
        return null
    }

    const renderBadge = (isDesktop = true) => {
        const text = renderTime()
        if (!text) return null
        if(!company) return null
        return <Pill className={classNames('cursor-pointer ml-auto lg:ml-0', {
            'hidden lg:flex': !isDesktop,
            'flex: lg:hidden': isDesktop
        })} onClick={() => setStatusOpen(true)} type={'warning'}>
            {iconsMapping[company.companyAddress.status]} {text}
        </Pill>
    }

    return (
        <WithMenuLayout headerContent={<div className={'flex items-center gap-4 flex-grow'}>
            <HeaderTabs items={headerLinks}/>
            {renderBadge(false)}
        </div>}>
            {
                company && <Suspense fallback={''}>
                <StatusSelector
                  isOpen={isStatusOpen}
                  onClose={() => {
                      setStatusOpen(false);
                      return Promise.resolve();
                  }}
                />
              </Suspense>
            }
                {renderBadge(true)}
            <Outlet/>
        </WithMenuLayout>
    );
};
